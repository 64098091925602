<template lang="html">
  <label class="checkbox">
    <input type="checkbox" :value="inputValue" v-model="model" />
    <span><span v-html="inputLabel"></span></span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    inputValue: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
